const HEALTH = [{
  // 企业管理
  path: '/HealthyEnterpriseInfoList',
  name: 'HealthyEnterpriseInfoList',
  // 按需加载路由
  component: () => import('@/views/douyi-health/enterprises-manage/HealthyEnterpriseInfoList'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录，不挂载该参数或该参数为false，表示不需要登录
    isLogin: true,
    // 是否需要鉴权，不挂载该参数或该参数为false，表示不需要鉴权
    isAuth: true,
    btnAuthList: ['状态']
  }
},
{
  // 企业流水
  path: '/HealthySettlingWaterList',
  name: 'HealthySettlingWaterList',
  // 按需加载路由
  component: () => import('@/views/douyi-health/enterprises-manage/HealthySettlingWaterList'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录，不挂载该参数或该参数为false，表示不需要登录
    isLogin: true,
    // 是否需要鉴权，不挂载该参数或该参数为false，表示不需要鉴权
    isAuth: true,
    btnAuthList: []
  }
},
{
  // 企业推荐
  path: '/HealthyAdvertisementList',
  name: 'HealthyAdvertisementList',
  // 按需加载路由
  component: () => import('@/views/douyi-health/placement-manage/HealthyAdvertisementList'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录，不挂载该参数或该参数为false，表示不需要登录
    isLogin: true,
    // 是否需要鉴权，不挂载该参数或该参数为false，表示不需要鉴权
    isAuth: true,
    btnAuthList: []
  }
},
{
  // 商品推荐
  path: '/HealthyComRecommendlist',
  name: 'HealthyComRecommendlist',
  // 按需加载路由
  component: () => import('@/views/douyi-health/placement-manage/HealthyComRecommendlist'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录，不挂载该参数或该参数为false，表示不需要登录
    isLogin: true,
    // 是否需要鉴权，不挂载该参数或该参数为false，表示不需要鉴权
    isAuth: true,
    btnAuthList: []
  }
},
{
  // 正在直播
  path: '/HealthyBroadcastList',
  name: 'HealthyBroadcastList',
  // 按需加载路由
  component: () => import('@/views/douyi-health/live-manage/HealthyBroadcastList'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录，不挂载该参数或该参数为false，表示不需要登录
    isLogin: true,
    // 是否需要鉴权，不挂载该参数或该参数为false，表示不需要鉴权
    isAuth: true,
    btnAuthList: []
  }
},
{
  // 直播回放
  path: '/HealthyLivePlayback',
  name: 'HealthyLivePlayback',
  // 按需加载路由
  component: () => import('@/views/douyi-health/live-manage/HealthyLivePlayback'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录，不挂载该参数或该参数为false，表示不需要登录
    isLogin: true,
    // 是否需要鉴权，不挂载该参数或该参数为false，表示不需要鉴权
    isAuth: true,
    btnAuthList: []
  }
},
{
  // 最新咨询
  path: '/HealthyInformationList',
  name: 'HealthyInformationList',
  // 按需加载路由
  component: () => import('@/views/douyi-health/information-manage/HealthyInformationList'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录，不挂载该参数或该参数为false，表示不需要登录
    isLogin: true,
    // 是否需要鉴权，不挂载该参数或该参数为false，表示不需要鉴权
    isAuth: true,
    btnAuthList: []
  }
},
{
  // 商品管理
  path: '/HealthyCommodityList',
  name: 'HealthyCommodityList',
  // 按需加载路由
  component: () => import('@/views/douyi-health/commodity-manage/HealthyCommodityList'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录，不挂载该参数或该参数为false，表示不需要登录
    isLogin: true,
    // 是否需要鉴权，不挂载该参数或该参数为false，表示不需要鉴权
    isAuth: true,
    btnAuthList: []
  }
},
{
  // 供货
  path: '/HealthyProvideList',
  name: 'HealthyProvideList',
  // 按需加载路由
  component: () => import('@/views/douyi-health/information-manage/HealthyProvideList'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录，不挂载该参数或该参数为false，表示不需要登录
    isLogin: true,
    // 是否需要鉴权，不挂载该参数或该参数为false，表示不需要鉴权
    isAuth: true,
    btnAuthList: []
  }
},
{
  // 求货
  path: '/HealthyPurchaseList',
  name: 'HealthyPurchaseList',
  // 按需加载路由
  component: () => import('@/views/douyi-health/information-manage/HealthyPurchaseList'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录，不挂载该参数或该参数为false，表示不需要登录
    isLogin: true,
    // 是否需要鉴权，不挂载该参数或该参数为false，表示不需要鉴权
    isAuth: true,
    btnAuthList: []
  }
},
{
  // 限时秒杀
  path: '/HealthyDeskillList',
  name: 'HealthyDeskillList',
  // 按需加载路由
  component: () => import('@/views/douyi-health/placement-manage/HealthyDeskillList'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录，不挂载该参数或该参数为false，表示不需要登录
    isLogin: true,
    // 是否需要鉴权，不挂载该参数或该参数为false，表示不需要鉴权
    isAuth: true,
    btnAuthList: []
  }
},
{
  // Redis限时秒杀
  path: '/HealthyRedisDeskillList',
  name: 'HealthyRedisDeskillList',
  // 按需加载路由
  component: () => import('@/views/douyi-health/placement-manage/HealthyRedisDeskillList'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录，不挂载该参数或该参数为false，表示不需要登录
    isLogin: true,
    // 是否需要鉴权，不挂载该参数或该参数为false，表示不需要鉴权
    isAuth: true,
    btnAuthList: []
  }
},
{
  // 线上博览会
  path: '/HealthyExpoBroadcastList',
  name: 'HealthyExpoBroadcastList',
  // 按需加载路由
  component: () => import('@/views/douyi-health/expo-manage/HealthyExpoBroadcastList'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录，不挂载该参数或该参数为false，表示不需要登录
    isLogin: true,
    // 是否需要鉴权，不挂载该参数或该参数为false，表示不需要鉴权
    isAuth: true,
    btnAuthList: []
  }
},
{
  // 博览会回放
  path: '/HealthyExpoLivePlayback',
  name: 'HealthyExpoLivePlayback',
  // 按需加载路由
  component: () => import('@/views/douyi-health/expo-manage/HealthyExpoLivePlayback'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录，不挂载该参数或该参数为false，表示不需要登录
    isLogin: true,
    // 是否需要鉴权，不挂载该参数或该参数为false，表示不需要鉴权
    isAuth: true,
    btnAuthList: []
  }
},
{
  // 博览会回放
  path: '/HealthyContactList',
  name: 'HealthyContactList',
  // 按需加载路由
  component: () => import('@/views/douyi-health/contact-manage/HealthyContactList'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录，不挂载该参数或该参数为false，表示不需要登录
    isLogin: true,
    // 是否需要鉴权，不挂载该参数或该参数为false，表示不需要鉴权
    isAuth: true,
    btnAuthList: []
  }
},
{
  // 轮播图
  path: '/HealthyBannerList',
  name: 'HealthyBannerList',
  // 按需加载路由
  component: () => import('@/views/douyi-health/banner-manage/HealthyBannerList'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录，不挂载该参数或该参数为false，表示不需要登录
    isLogin: true,
    // 是否需要鉴权，不挂载该参数或该参数为false，表示不需要鉴权
    isAuth: true,
    btnAuthList: []
  }
},
{
  // 视频推荐
  path: '/HealthyPublicityVideoList',
  name: 'HealthyPublicityVideoList',
  // 按需加载路由
  component: () => import('@/views/douyi-health/placement-manage/HealthyPublicityVideoList'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录，不挂载该参数或该参数为false，表示不需要登录
    isLogin: true,
    // 是否需要鉴权，不挂载该参数或该参数为false，表示不需要鉴权
    isAuth: true,
    btnAuthList: []
  }
},
{
  // 助手问答
  path: '/HealthyManualList',
  name: 'HealthyManualList',
  // 按需加载路由
  component: () => import('@/views/douyi-health/manual-manage/HealthyManualList'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录，不挂载该参数或该参数为false，表示不需要登录
    isLogin: true,
    // 是否需要鉴权，不挂载该参数或该参数为false，表示不需要鉴权
    isAuth: true,
    btnAuthList: []
  }
}
]
export default HEALTH
